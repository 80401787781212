import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/about": [5],
		"/account": [6],
		"/error": [7],
		"/legal": [8,[2]],
		"/legal/cookies": [9,[2]],
		"/legal/copyright": [10,[2]],
		"/legal/privacyOutline": [12,[2]],
		"/legal/privacy": [11,[2]],
		"/legal/sharing": [13,[2]],
		"/legal/subscriptions": [14,[2]],
		"/legal/terms": [15,[2]],
		"/menu": [16],
		"/playlists": [17],
		"/privacyPolicy": [18],
		"/qrcode": [19],
		"/qrcode/businesscard": [20],
		"/[...catchall]": [4]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';